<template>
  <div class="other-live-room">
    <div class="operate">
      <div class="filter-con">
        <label class="label" for="filter-input">学生名称</label>
        <el-input v-model="filterKeyWord" class="filter-input" type="text" id="filter-input" placeholder="输入学生名称进行搜索" @keydown.native.enter="filterQuery"/>
        <el-button class="query" type="primary" plain @click="filterQuery">查询</el-button>
      </div>
      <button class="back" @click="back">返回</button>
    </div>
    <div class="content">
      <el-scrollbar style="height: 100%">
        <div class="room-list">
          <div class="room-item" v-for="(item, index) in viewList" :key="index" @click="seeLive(item)">
            <div class="head"  :style="{backgroundImage:item.getStudentInfo.getOnliveRoom===null?'':'url('+item.getStudentInfo.getOnliveRoom.cover+')'}">
              <div class="live-base-info">
                <div class="people-num">
                  <i class="el-icon-user-solid icon"></i>
                  <div class="num">{{ item.online_total_number }}</div>
                </div>
                <div
                  class="state-box"
                  :class="[item.state === 1 ? 'live-ing' : 'live-end']"
                  v-if ='item.getStudentInfo.getOnliveRoom === null || item.getStudentInfo.getOnliveRoom.is_has_push === 0'
                >
                  <i class="iconfont icon" v-if="item.state == 1">&#xe63c;</i>
                  <div class="state" v-if="!!item.getStudentInfo.getOnliveRoom">{{ liveState[item.getStudentInfo.getOnliveRoom.status] }}</div>
                  <div class="state" v-else>暂无直播</div>
                </div>
                <div
                  class="state-box"
                  :class="[item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_has_push === 1? 'live-ing' : 'live-end']"
                  v-else
                >
                  <i class="iconfont icon" v-if="item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_has_push === 1">&#xe63c;</i>
                  <div class="state">{{ liveState[item.getStudentInfo.getOnliveRoom.status] }}</div>
                </div>
              </div>
            </div>
            <div class="live-info">
              <div class="live-title" v-if="item.getStudentInfo.getOnliveRoom ==null" >暂无直播</div>
              <div class="live-title" v-else >{{item.getStudentInfo.getOnliveRoom.title}}</div>
              <div class="user-info">
                <div v-if="item.getStudentInfo.getOnliveRoom ==null" class="avatar">
                  <img
                    class="avatar-img"
                    :src="item.avatar"
                    :alt="item.username"
                    :title="item.username"
                  />
                </div>
                 <div v-else class="avatar">
                  <img
                    class="avatar-img"
                    :src="item.getStudentInfo.getOnliveRoom.avatar"
                    :alt="item.getStudentInfo.getOnliveRoom.nickname"
                    :title="item.getStudentInfo.getOnliveRoom.nickname"
                  />
                </div>
                <div v-if="!item.getStudentInfo.getOnliveRoom" class="username">暂无主播</div>
                <div v-else class="username">{{item.getStudentInfo.getOnliveRoom.nickname}}</div>
              </div>
            </div>
          </div>
        </div>
        
      </el-scrollbar>
    </div>
     <el-pagination
          class="pages-center"
          :current-page="listPages.currentPageNum"
          :page-size="listPages.eachPageNum"
          layout="prev, pager, next, jumper"
          :total="listPages.total"
          @current-change="pageCurrentChange"
        >
        </el-pagination>
  </div>
</template>

<script>
import { otherPeople, otherScript } from "@/utils/apis";
export default {
  name: "OtherLiveRoom",
  data() {
    return {
      role: Number(localStorage.getItem('role')),
      list: [],
      viewList: [],
         listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      filterKeyWord: "",
      liveState: ["未开播","直播中", "直播结束", "直播暂停"],
      img:'https://tse1-mm.cn.bing.net/th/id/R-C.35bcc4c4b02ffbfba073b807044b6e27?rik=gjZqaq%2b6HSBX2A&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20200505%2f2ea8ba335e9f4f3eac817338cfc5f3e0.jpeg&ehk=MG1RvptD6F%2bZNf6%2fMmXafOu6AlO6oe1qQIv35X2xd%2fE%3d&risl=&pid=ImgRaw&r=0'
    };
  },
  computed: {},
  components: {},

  
  mounted() {
    // new Array(10).fill(1).forEach(() =>
    //   this.list.push({
    //     title: "直播间标题",
    //     username: "root",
    //     avatar: require("assets/image/blank_headPic.png"),
    //     bgImg:
    //       "https://tse1-mm.cn.bing.net/th/id/R-C.35bcc4c4b02ffbfba073b807044b6e27?rik=gjZqaq%2b6HSBX2A&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20200505%2f2ea8ba335e9f4f3eac817338cfc5f3e0.jpeg&ehk=MG1RvptD6F%2bZNf6%2fMmXafOu6AlO6oe1qQIv35X2xd%2fE%3d&risl=&pid=ImgRaw&r=0",
    //     state: parseInt(Math.random() * 3) + 1,
    //     num: 5676,
    //   })
    // );
    if (this.role === 4) {
      this.list = [
        {
          "id": 40,
          "user_id": 113,
          "banji_id": 37,
          "create_time": "2022-02-14 16:53:38",
          "update_time": "2022-02-14 16:53:38",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 113,
            "nickname": "陈四",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 41,
          "user_id": 114,
          "banji_id": 37,
          "create_time": "2022-02-14 16:53:38",
          "update_time": "2022-02-14 16:53:38",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 114,
            "nickname": "陈五",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 42,
          "user_id": 115,
          "banji_id": 37,
          "create_time": "2022-02-14 16:53:38",
          "update_time": "2022-02-14 16:53:38",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 115,
            "nickname": "陈六",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 43,
          "user_id": 116,
          "banji_id": 37,
          "create_time": "2022-02-14 16:53:38",
          "update_time": "2022-02-14 16:53:38",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 116,
            "nickname": "陈七",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 44,
          "user_id": 117,
          "banji_id": 37,
          "create_time": "2022-02-14 16:53:38",
          "update_time": "2022-02-14 16:53:38",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 117,
            "nickname": "陈八",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 45,
          "user_id": 118,
          "banji_id": 37,
          "create_time": "2022-02-14 16:53:38",
          "update_time": "2022-02-14 16:53:38",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 118,
            "nickname": "陈九",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 46,
          "user_id": 119,
          "banji_id": 37,
          "create_time": "2022-02-14 16:54:07",
          "update_time": "2022-02-14 16:54:19",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 119,
            "nickname": "李一",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 143,
          "user_id": 263,
          "banji_id": 37,
          "create_time": "2022-05-19 09:39:08",
          "update_time": "2022-05-19 09:39:08",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 263,
            "nickname": "陈十一",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 144,
          "user_id": 264,
          "banji_id": 37,
          "create_time": "2022-05-19 09:39:25",
          "update_time": "2022-05-19 09:39:25",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 264,
            "nickname": "陈十二",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        },
        {
          "id": 145,
          "user_id": 265,
          "banji_id": 37,
          "create_time": "2022-05-19 09:39:36",
          "update_time": "2022-05-19 09:39:36",
          "delete_time": 0,
          "onlive_time": 0,
          "online_total_number": 0,
          "getStudentInfo": {
            "id": 265,
            "nickname": "陈十三",
            "getOnliveRoom": null,
            "getLiveUrl": []
          }
        }
      ]
      this.viewList = this.list;
    } else {
      this.getDate();
    }
  },
  methods: {
    filterQuery() {
      this.viewList = this.filterKeyWord === "" || this.filterKeyWord === undefined || !this.list || this.list.length <= 0 ? this.list 
      : this.list.filter(item => item.getStudentInfo.getOnliveRoom && item.getStudentInfo.getOnliveRoom.nickname.includes(this.filterKeyWord));
    },
      // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.getDate();
    },
    back() {
      this.$router.back();
    },
    getDate() {
      let params = {
        train_id: JSON.parse(localStorage.getItem("train_id")),
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      };
      otherPeople(params)
        .then((res) => {
          if (res.code == 200) {
             this.list = res.data.list
             this.listPages.total = res.data.total
             this.viewList = this.list;
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    async seeLive(item) {
      let scriptInfo = {};
      let params = {train_id: item.getStudentInfo.getOnliveRoom.train_id};
      let res = await otherScript(params)
      if (res.code == 200) {
        scriptInfo = res.data
      }
      
      let getLiveUrlLast = item.getStudentInfo.getLiveUrl.slice(-1)[0];
      let LiveUrlLast = getLiveUrlLast.webrtc_rtmp.trim();
      let routeUrl = this.$router.resolve({
        name: 'otherStuLiveRoom',
        path: '/otherStuLiveRoom',
        query: {
          tid: item.getStudentInfo.getOnliveRoom.user_id,
          bind_id: Number(localStorage.getItem('studentId')),
          t_Train_id: item.getStudentInfo.getOnliveRoom.train_id,
          t_Room_id: item.getStudentInfo.getOnliveRoom.id,
          play_url: LiveUrlLast,
          play_url_rtmp: getLiveUrlLast.play_rtmp,
          script_id: scriptInfo.script_id,
          advert_id: scriptInfo.advert_id,
        }
      });
      window.open(routeUrl.href, "_blank");
    }
  },
};
</script>

<style scoped lang="scss">
.other-live-room {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: hidden;
  background-image: linear-gradient(to right, #070932, #12144d);
  .operate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: end;
    .filter-con {
      color: #fff;
      ::v-deep .filter-input {
        width: 250px;
        margin: 0 24px 0 10px;
        #filter-input {
          background-color: transparent;
          border-color: #4B45FF;
          color: #fff;
          &:focus {
            border-color: #fff;
          }
        }
      }
      .query {
        color: #fff;
        background-color: transparent;
        border-color: #4B45FF;
        &:hover {
          background-color: #4B45FF;
        }
      }
    }
    .back {
      position: relative;
      width: 90px;
      height: 40px;
      color: #fff;
      font-size: 16px;
      border: none;
      background-color: #4b45ff;
      border-radius: 4px;
      cursor: pointer;
      &:hover::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .content {
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
    .room-list {
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(auto-fill, 250px);
      row-gap: 40px;
      column-gap: 20px;
      justify-content: space-between;
      padding-right: 1px;
      .room-item {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 250px;
        height: 300px;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid transparent;
        cursor: pointer;
        &:hover {
          border-color: #4b45ff;
        }
        .head {
          display: flex;
          align-items: flex-end;
          width: 100%;
          height: 204px;
          background-image: url("https://tse1-mm.cn.bing.net/th/id/R-C.35bcc4c4b02ffbfba073b807044b6e27?rik=gjZqaq%2b6HSBX2A&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20200505%2f2ea8ba335e9f4f3eac817338cfc5f3e0.jpeg&ehk=MG1RvptD6F%2bZNf6%2fMmXafOu6AlO6oe1qQIv35X2xd%2fE%3d&risl=&pid=ImgRaw&r=0");
          background-size: 100% 100%;
          .live-base-info {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
            padding: 0 10px;
            background-color: rgba(0, 0, 0, 0.5);
            .people-num {
              display: flex;
              align-items: center;
              color: #fff;
              font-size: 16px;
              .icon {
                margin-right: 10px;
                font-size: 20px;
              }
            }
            .state-box {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80px;
              height: 26px;
              font-size: 14px;
              border-radius: 4px;
              .icon {
                margin-right: 4px;
                font-size: 12px;
              }
            }
            .live-ing {
              color: #fff;
              background-color: #fb0f4b;
            }
            .no-start,
            .live-end {
              color: #5b5b6d;
              background-color: #cfd1db;
            }
          }
        }
        .live-info {
          flex: 1;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 13px;
          color: #fff;
          .live-title {
            margin-bottom: 10px;
            font-size: 16px;
          }
          .user-info {
            display: flex;
            align-items: center;
            .avatar {
              width: 24px;
              height: 24px;
              margin-right: 9px;
              border-radius: 50%;
              .avatar-img {
                width: 100%;
                height: 100%;
                vertical-align: middle;
              }
            }
            .username {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .pages-center{
  background: none;
  padding: 0px 0px !important;
  margin-top: 10px;
}
::v-deep .el-pagination button:disabled{
      background: none;
          color: #909399
}
::v-deep .el-pagination .btn-next{
   background: none;
      color: #fff
}
::v-deep .el-pagination .btn-prev{
    background: none;
          color: #fff
}
::v-deep .el-pager li{
    background: none;
    color: #fff !important;
}
</style>
